import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './Shared/layout/layout.component';
import { AuthGuard } from '../Helper/AuthGuard';
const routes: Routes = [
  {
    path: "",
    redirectTo: "/Login",
    pathMatch: "full"
  },
  {
    path: "Login",
    loadChildren: () => import('./Login/login.component').then(o => o.LoginModule)
  },
  {
    path: "Forgot",
    loadChildren: () => import('./ForgotPassword/forgot-password.component').then(o => o.ForgotPasswordModule)
  },
  {
    path: "ResetPassword/:id",
    loadChildren: () => import('./ResetPassword/reset-password.component').then(o => o.ResetPasswordModule)
  },
  {
    canActivate: [AuthGuard],
    path: "AdminDashboard", component: LayoutComponent,
    loadChildren: () => import('./AdminDashboard/admindashboard.compponent').then(o => o.AdminDashboardModule),
    data: { MenuName: "Admin Dashboard", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "DashboardFilterList/:type/:WorkingAsId/:JobcityId", component: LayoutComponent,
    loadChildren: () => import('./DashboardFilterList/dashboardfilterlist.component').then(o => o.DashboardFilterListModule),
    data: { MenuName: "Admin Dashboard", Module: "Admin" }
  },

  {
    canActivate: [AuthGuard],
    path: "UserList",
    component: LayoutComponent, loadChildren: () => import('./User/User-List/userlist.component').then(o => o.UserlistModule),
    data: { MenuName: "User", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "User/:id", component: LayoutComponent,
    loadChildren: () => import('./User/User/user.component').then(o => o.UserModule),
    data: { MenuName: "User", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "UserRole/:id", component: LayoutComponent,
    loadChildren: () => import('./UserRole/User_Role/userrole.component').then(o => o.UserRoleModule),
    data: { MenuName: "User Role", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "UserRoleList", component: LayoutComponent,
    loadChildren: () => import('./UserRole/user-RoleList/user-rolelist.component').then(o => o.UserRoleListModule),
    data: { MenuName: "User Role", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Rashi", component: LayoutComponent,
    loadChildren: () => import('./Rashi/rashi.component').then(o => o.RashiModule),
    data: { MenuName: "Rashi", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "EducationCourse", component: LayoutComponent,
    loadChildren: () => import('./EducationCourse/educationcourse.component').then(o => o.EducationCourseModule),
    data: { MenuName: "EducationCourse", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "EducationField", component: LayoutComponent,
    loadChildren: () => import('./EducationField/educationfield.component').then(o => o.EducationFieldModule),
    data: { MenuName: "EducationField", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "EducationLevel", component: LayoutComponent,
    loadChildren: () => import('./EducationLevel/educationlevel.component').then(o => o.EducationLevelModule),
    data: { MenuName: "EducationLevel", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "WorkingAs", component: LayoutComponent,
    loadChildren: () => import('./WoringAs/workingas.component').then(o => o.WorkingAsModule),
    data: { MenuName: "WorkingAs", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Nakshatra", component: LayoutComponent,
    loadChildren: () => import('./Nakshatra/nakshatra.component').then(o => o.NakshatraModule),
    data: { MenuName: "Nakshatra", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Gotra", component: LayoutComponent,
    loadChildren: () => import('./Gotra/gotra.component').then(o => o.GotraModule),
    data: { MenuName: "Gotra", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Lagna", component: LayoutComponent,
    loadChildren: () => import('./Lagna/lagna.component').then(o => o.LagnaModule),
    data: { MenuName: "Lagna", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Caste", component: LayoutComponent,
    loadChildren: () => import('./Caste/caste.component').then(o => o.CasteModule),
    data: { MenuName: "Caste", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "MotherTongue", component: LayoutComponent,
    loadChildren: () => import('./MotherTongue/mother_tongue.component').then(o => o.MotherTongueModule),
    data: { MenuName: "MotherTongue", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "State", component: LayoutComponent,
    loadChildren: () => import('./State/state.component').then(o => o.StateModule),
    data: { MenuName: "State", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Country", component: LayoutComponent,
    loadChildren: () => import('./Country/country.component').then(o => o.CountryModule),
    data: { MenuName: "Country", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "Religion", component: LayoutComponent,
    loadChildren: () => import('./Religion/religion.component').then(o => o.ReligionModule),
    data: { MenuName: "Religion", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "ProfileFilter", component: LayoutComponent,
    loadChildren: () => import('./ProfileFilter/profilefilter.component').then(o => o.ProfileFilterModule),
    data: { MenuName: "ProfileFilter", Module: "Admin" }
  },

  {
    canActivate: [AuthGuard],     
    path: "Profile/:id", component: LayoutComponent,
    loadChildren: () => import('./Profile/profile/profile.component').then(o => o.ProfileModule),
    data: { MenuName: "Profile", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "ProfileList", component: LayoutComponent,
    loadChildren: () => import('./Profile/profile_list/profile_list.component').then(o => o.ProfileListModule),
    data: { MenuName: "ProfileList", Module: "Admin" }
  },
  {
    canActivate: [AuthGuard],
    path: "City", component: LayoutComponent,
    loadChildren: () => import('./City/city.component').then(o => o.CityModule),
    data: { MenuName: "City", Module: "Admin" }
  }, 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
