<form [formGroup]="ChangePasswordForm">
    <div class="row">
        <div class="col-lg-12 mb-3 form-group">
            <label>Old Password<span style="color: red;"> * </span></label>
            <p-password [feedback]="false" [toggleMask]="true" autocomplete="old_password"
                placeholder="Enter Old Password" styleClass="w-100 d-grid" formControlName="old_password"
                [(ngModel)]="old_password"></p-password><app-validation propertyName="old_password"
                [MessageArray]="ChangePasswordValidationMessages" [FormGroupName]="ChangePasswordForm"></app-validation>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 mb-3 form-group">
            <label>New Password<span style="color: red;"> * </span></label>
            <p-password appendTo="body" [toggleMask]="true" autocomplete="password" placeholder="Enter New Password"
                styleClass="w-100 d-grid" formControlName="password" [(ngModel)]="password"><ng-template pTemplate="footer">
                    <hr>
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </ng-template></p-password><app-validation propertyName="password"
                [MessageArray]="ChangePasswordValidationMessages" [FormGroupName]="ChangePasswordForm"></app-validation>
        </div>
        <div class="col-lg-6 mb-3 form-group">
            <label>Confirm Password<span style="color: red;"> * </span></label>
            <p-password appendTo="body" [toggleMask]="true" autocomplete="confirm_password"
                placeholder="Enter Confirm Password" styleClass="w-100 d-grid" formControlName="confirm_password"
                [(ngModel)]="confirm_password"><ng-template pTemplate="footer">
                    <hr>
                    <p class="mt-2">Suggestions</p>
                    <ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
                        <li>At least one lowercase</li>
                        <li>At least one uppercase</li>
                        <li>At least one numeric</li>
                        <li>Minimum 8 characters</li>
                    </ul>
                </ng-template></p-password><app-validation propertyName="confirm_password"
                [MessageArray]="ChangePasswordValidationMessages" [FormGroupName]="ChangePasswordForm"></app-validation>
            <div style='color:red'
                *ngIf="ChangePasswordForm?.get(['password']).value != ChangePasswordForm?.get(['confirm_password']).value">
                Password does not match</div>
        </div>
    </div>
</form>
<div class="row">
    <div class="col-lg-12 mt-3" style="text-align: right">
        <button pButton pRipple class="p-button-secondary me-3 mb-3" icon="pi pi-times-circle" label="Cancel"
            (click)="Cancel()"></button>
        <button pButton pRipple class="p-button-success me-3 mb-3" icon="pi pi-check-circle" label="Confirm"
            (click)="ChangePassword()"></button>
    </div>
</div>