<span class="badge bg-info" *ngIf="lable == 'ST'">Sent</span>
<span class="badge bg-secondary" *ngIf="lable == 'S'">Saved</span>
<span class="badge bg-danger" *ngIf="lable == 'E'">Expired</span>
<span class="badge" style="background-color: lightsalmon;" *ngIf="lable == 'O'">Overdue</span>
<span class="badge bg-success" *ngIf="lable == 'A'">Active</span>
<span class="badge" style="background-color: lightskyblue;" *ngIf="lable == 'D'">Draft</span>
<span class="badge" style="background-color: lightpink;" *ngIf="lable == 'V'">Void</span>
<span class="badge" style="background-color: chocolate;color: black;" *ngIf="lable == 'P'">Paid</span>
<span class="badge" style="background-color: darkcyan;" *ngIf="lable == 'PP'">Partial Paid</span>
<span class="badge bg-danger" *ngIf="lable == 'C'">Close</span>
<span class="badge bg-danger" *ngIf="lable == 'ED'">Ended</span>
<span class="badge bg-success" *ngIf="lable == 'Active'">Active</span>
<span class="badge bg-danger" *ngIf="lable == 'Suspend'">Suspend</span>
<span class="badge bg-success" *ngIf="lable == 'Yes'">Yes</span>
<span class="badge bg-danger" *ngIf="lable == 'No'">No</span>